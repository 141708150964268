// import classes from './Header.module.css'
import Box  from '@mui/material/Box'
import Typography from '@mui/material/Typography';
import { styled} from '@mui/material/styles';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Button from '@mui/material/Button';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Badge from '@mui/material/Badge';
import dynamic from 'next/dynamic';
import { useSelector } from 'react-redux';
import Link from 'next/link';
import useAuth from '@components/_hooks/useAuth';
import useDialog from '@components/_hooks/useDialog';


const SideBarModalRight = dynamic(() => import("@components/Components/Modals/SideBar/SideBarModal"), {
    ssr: false,
});

const Cart = dynamic(() => import("@components/Components/Nav/Cart"), {
  ssr: false,
});
const StyledBox = dynamic(() => import("@components/Custom/Box/StyledBox"));

const StyledBadge = styled(Badge)(({ theme }) => ({
    // '& .MuiBadge-badge': {
    //   backgroundColor:'#fff',
    //   fontWeight:'800',
    //   color:'var(--color-primary)'
    // },
  
    '& .MuiBadge-badge': {
      // border: `0px solid ${theme.palette.background.paper}`,
      // padding:0,
      // width:15,
      // height:15,
      // borderRadius: '100%',
      fontSize:12,
      backgroundColor:'var(--color-secondary)',
      padding: 0,
      // display: 'block',
      [theme.breakpoints.up('lg')]: {
          top: '15%'
        },
      [theme.breakpoints.down('lg')]: {
          top: 0
      },
    },
  }));
const HeaderTools = () => {
    const cart = useSelector(state => state.cartManagementReducer.cart);
    const global_settings = useSelector(state => state.globalReducer.global_settings);
    const template_settings = useSelector(state => state.globalReducer.template_settings);
    const user = useSelector(state => state.authReducer.user);
    const isAuthenticated = useSelector(state => state.authReducer.isAuthenticated);

    const {dialog, handleDialog} = useDialog();
    const {isAgent} = useAuth();

    return (
        <>
        <Box className='mr-2 mt-2 d-flex justify-end pb-0' sx={{ minHeight: 50, alignItems: 'end'}}>
            { (global_settings.portal_status && global_settings.portal_status == 'enabled') || (template_settings.portal_status && template_settings.portal_status == 'enabled') && (
              <Link href={`${user ? isAgent() ? process.env.NEXT_PUBLIC_USER_CRM_ROUTE : process.env.NEXT_PUBLIC_USER_HOME_ROUTE : process.env.NEXT_PUBLIC_USER_LOGIN_URL}`} className='text-vertical d-flex'>
                	<StyledBox className='mr-2 mt-1 d-flex justify-space-between align-items-center flex-direction-row text-vertical text-vertical' >
                  <AccountCircleIcon sx={{color: 'primary.main',fontSize:28}}/>

                    <Typography  
                      className={`mr-1`} component={'span'} variant='contain' fontSize={14} 
                      sx={{
                          border:'none', 
                          boxShadow:'none', 
                          color: 'primary.main',
                          cursor: 'pointer',
                          marginLeft: '5px',
                          marginTop: '1px',
                        }}
                      >
                      {isAuthenticated ? "My Account"  : "Login / Register"}
                  </Typography>
                </StyledBox>
              </Link>
            )}
            <StyledBox sx={{marginTop:0.9}}>
            <Button className='basket-button pl-2 pr-3 pt-2 pb-2 text-vertical' variant='contained' onClick={() => handleDialog('right')}>
                <StyledBadge>
                  <Badge 
                    badgeContent={cart?.counter || 0}
                    anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                    <ShoppingCartIcon sx={{color:'#fff', marginLeft:1, fontSize:22}} className={`${ cart?.counter > 0 && 'mr-1'}`}/>
                </Badge>
                    <Typography fontSize={14} className= {`${ cart?.counter > 0 ? 'ml-2' : 'ml-1'}`}>Cart</Typography> 
                </StyledBadge>
            </Button>
            </StyledBox>
        </Box>
         <Box>
         {dialog['right'].open && <SideBarModalRight anchor={'right'} title={'Cart'} children={<Cart handleDialog={() => handleDialog('right')}/>} handleDialog={() => handleDialog('right')} open={dialog['right'].open}/>}
     </Box>
        </>
    )
}

export default HeaderTools